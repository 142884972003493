import React, { useEffect, useState } from "react";
import * as s from "../styles/global";
import logonTitle from "../assets/images/logonTitle.png";
import imgOne from "../assets/images/imgOne.png";
import imgTwo from "../assets/images/imgTwo.png";
import imgThree from "../assets/images/imgThree.png";
import imgFour from "../assets/images/imgFour.png";
import { Link } from "react-router-dom";

import "./index.css";

const LaunchpadNewBuy = () => {
  return (
    <>
      <s.launchpadNew ai="center" className="launchpadNewBuy_container">
        <s.LogoTitleOne src={logonTitle} />
        <div className="launchpadNewBuy_content">
          <div className="launchpadNewBuy_content_two">
            MerlinPad.io <br></br>MerlinChain launchpad#1 Official link：{" "}
            <a
              href="https://scan.merlinchain.io/dapp/48"
              target="_blank"
              className="custom-link"
            >
              https://scan.merlinchain.io/dapp/48
            </a>
            <br></br> X：
            <a
              href="https://twitter.com/merlinpad_io"
              target="_blank"
              className="custom-link"
            >
              https://twitter.com/merlinpad_io
            </a>{" "}
            <br></br>TG：
            <a
              href="https://t.me/merlinpad "
              target="_blank"
              className="custom-link"
            >
              https://t.me/merlinpad 
            </a>
          </div>
          <div className="launchpadNewBuy_content_two">
            Professional decentralized LaunchPad platform focusing on
            Merlinchain! <br></br>Launch 3 types of assets: tokens, NFTs and
            Ordinals <br></br> Contains assets such as Ordinals, BRC20, BRC420,
            Atomicals, etc. <br></br>All projects are issued by smart contracts,
            with automatic LP formation,<br></br> automatic contract 
            locking and many other functions
          </div>
          <div className="launchpadNewBuy_content_two">
            MerlinPad economic model: <br></br>Number of MTM Issued: 210 Billion
            Ordinals <br></br> IDO Valuation: 100 BTC (6.8 million USDT)
            <br></br>1BTC = 2.1 billion MTM <br></br>
            1MTM=0.00003U <br></br>1MTM=0.0000000004BTC
          </div>
        </div>
        <s.LogoTitleTwo src={imgOne} />
        <s.LogoTitleTwo src={imgTwo} />

        <div className="launchpadNewBuy_content_one">
          Official NFT: 5000 <br></br>NFT contract address:
          0x594f664ad9fdea92d36a220681bb857cfe15d4b4<br></br>NFT Marketplace:<br></br>
          <a
              href="https://element.market/collections/postpsychedelia"
              target="_blank"
              className="custom-link"
            >
              https://element.market/collections/postpsychedelia 
            </a>
          <br></br>NFT is an American NFT brand acquired by MerlinPad<br></br>We
          will be developing blockchain games based on this NFT
        </div>
        <s.LogoTitleTwo src={imgThree} />
        <s.LogoTitleTwo src={imgFour} />
        <div className="launchpadNewBuy_content">
          <div className="launchpadNewBuy_content_two">
            All free airdrops: <br></br>4000 to IDO participants, 1000 to
            airdrop partner official<br></br>NFT permanent destruction
            mechanism, launching all 3 assets must consume NFT as fuel <br></br>
            So the platform has a limited number of items that can be launched
            in the end.<br></br>NFT will become more and more precious {" "}
          </div>

          <div className="launchpadNewBuy_content_two">
            Use of BTC gained from this launch. <br></br>50% BTC for mandatory
            LP formation (   <a
              href="https://merlinswap.org/"
              target="_blank"
              className="custom-link"
            >
              https://merlinswap.org/ 
            </a>) <br></br>50% BTC for official
            NFT buyback to guarantee early participants' returns
            (
            <a
              href="https://element.market/"
              target="_blank"
              className="custom-link"
            >
              https://element.market/ 
            </a>
            )
          </div>
        </div>
      </s.launchpadNew>
    </>
  );
};

export default LaunchpadNewBuy;
