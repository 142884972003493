import Request from './api';

export const getUserData = async (params) => {
    try {
      const res = await Request.get('/bapi/v1/merlin-pad/detail', { params });
      return res;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error; // 可以选择抛出错误以供调用者处理
    }
  };

  export const postUserData = async (data) => {
    try {
      const res = await Request.post('/bapi/v1/merlin-pad/place', { ...data });
      return res;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error; // 可以选择抛出错误以供调用者处理
    }
  };

  export const getAllUserData = async () => {
    try {
      const res = await Request.get('/bapi/v1/merlin-pad/all');
      return res;
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error; // 可以选择抛出错误以供调用者处理
    }
  };
