import axios from 'axios';
import qs from 'qs';
// import baseUrl from '../config';
const baseUrl = 'https://api.merlinpad.io'
const instance = axios.create({
  timeout: 600 * 1000,
});

instance.interceptors.request.use(
  config => {
    const configData = config;
      configData.headers = {
        'content-type': 'application/json',
        ...configData.headers,
      };
    return configData;
  },
  error => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  res => {
    const { data, status } = res;

    if (status === 200 ) {  
      return data;
    }
     else {
      return false;
    }
  },
  () => {
    return false;
  },
);

export default class Request {
  static get(url, config) {
    return instance.get(baseUrl + url, config);
  }

  static post(url, data, stringify, config) {
    return instance.post(baseUrl + url, stringify ? data :data, config);
  }

  static delete(url, config) {
    return instance.delete(baseUrl + url, config);
  }
}
