import ETH from './eth.png'
import BNB from './bnb.png'
import BTC from './merlin.png'
export const CURRENCY = {
  ETH,
  BNB,
  BTC,
  // ------------

  1: ETH,
  3: ETH,
  4: ETH,
  5: ETH,
  42: ETH,
  56: BNB,
  97: BNB,
  4200:BTC,
  686868:BTC,

}
