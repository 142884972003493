import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as s from "../styles/global";
import { utils } from "../utils";
import { useNavigate, useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import NftLogo from "../assets/images/nftLogo.png";
import tiwterLogo from "../assets/images/tiwterLogo.png";
import WalletModal from "../components/WalletModal";
import Web3 from "web3";
import { getUserData, postUserData, getAllUserData } from "../api";
import "./index.css";
import { useApplicationContext } from "../context/applicationContext";
import LaunchpadNewBuy from "./launchpadNewBuy.js";
import BigNumber from "bignumber.js";
import { notification, Input } from "antd";
import copy from "../assets/images/copy.png";

const LaunchpadNew = (props) => {
  const { ETHamount, triggerUpdateAccountData } = useApplicationContext();
  const [showBuy, setShowBuy] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isWaleltModalOpen, setIsWaleltModalOpen] = useState(false);

  const { account, library } = useWeb3React();
  const [Inputvalue, setValue] = useState(""); // 使用状态管理输入框的值
  const [dataValue, setdataValue] = useState({}); // 使用状态管理输入框的值
  const [transactionHash, setTransactionHash] = useState(""); //hash
  const [FeeTokenamountMax, setFeeTokenamount] = useState(0); // 使用状态管理输入框的值
  const [MTMNum, setMTMNum] = useState(0); // 使用状态管理输入框的值
  const [totalAmount, setTotalAmount] = useState(""); // 使用状态管理输入框的值

  const [WaleltStatus, setIsWaleltStatus] = useState("BUY $MTM");
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (ETHamount) {
      const num = BigNumber(ETHamount)
        .dividedBy(10 ** 18)
        .toNumber()
        .toFixed(6);
      if (num) {
        setFeeTokenamount(num);
      }
    }
  }, [ETHamount]); // 当showAlert变化时执行此effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      const targetDate = new Date(2024, 3, 30, 10, 30, 0); // 注意：月份是从0开始计数的，所以4月对应的是3

      const now = new Date();

      const difference = targetDate - now;

      if (difference <= 0) {
        clearInterval(intervalId);
        // 如果倒计时结束，您可以在此处执行一些操作，比如显示消息或触发事件
        console.log("Countdown finished!");
        setIsWaleltStatus("COMPLETE");
      } else {
        const daysLeft = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hoursLeft = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutesLeft = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const secondsLeft = Math.floor((difference % (1000 * 60)) / 1000);

        setDays(daysLeft);
        setHours(hoursLeft);
        setMinutes(minutesLeft);
        setSeconds(secondsLeft);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);
  const handleChange = (event) => {
    const { value } = event.target;
    // 只允许输入数字且是正数
    const newValue = value.replace(/[^\d.]/g, ""); // 移除非数字字符
    setValue(newValue);
  };
  function formatCurrency(amount) {
    // 使用 toLocaleString 方法将数字格式化为金额类型
    if (amount) {
      const value = amount * 70000;
      return value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } else {
      return 0;
    }
  }

  async function fetchData() {
    try {
      const currentTimeStamp = Date.now();
      const params = { address: account, timestamp: currentTimeStamp };
      const userData = await getUserData(params); // Assuming 123 is the userId
      if (userData) {
        setdataValue(userData.data);
        if (userData.data.amount) {
          const num = Number(userData.data.amount) * 2100000000;
          setMTMNum(num);
        }
      }
      // setUser(userData);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }
  // 使用 useEffect 启动和停止定时器
  const fetchDataAll = () => {
    if (account) {
      fetchData();
    }
    getAllUserDataALL();
  };
  useEffect(() => {
    // 在组件挂载后立即执行一次请求

    // 设置定时器，每两秒执行一次请求
    const intervalId = setInterval(fetchDataAll, 3000);

    // 在组件卸载前清除定时器
    return () => clearInterval(intervalId);
  }, [account]); // 这个空数组表示仅在组件挂载和卸载时执行一次
  useEffect(() => {
    if (!account) {
      setIsWaleltStatus("CONNECT");
      setFeeTokenamount(0);
      setdataValue({});
    } else {
      fetchData();

      setIsWaleltStatus("BUY $MTM");
    }
    // if (location.pathname === '/launchpad') navigate('/launchpad');
    getAllUserDataALL();
  }, [account]);
  const openNotification = (type, description) => {
    notification[type]({
      // message: 'Notification Title',
      description: description,
      placement: "bottomRight",
    });
  };
  async function handlepostUserData(params) {
    try {
      const updatedUserData = await postUserData(params);
      // setUser(updatedUserData);
      fetchData();
      setValue("");
      triggerUpdateAccountData();
      openNotification("success", "Successful transaction!");
    } catch (error) {
      console.error("Error updating user:", error);
    }
  }
  async function getAllUserDataALL() {
    try {
      const updatedUserData = await getAllUserData();
      if (updatedUserData && updatedUserData.data) {
        setTotalAmount(updatedUserData.data.totalAmount);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  }
  const buyChange = () => {
    setShowBuy(true);
  };
  const clickMax = async () => {
    if (FeeTokenamountMax) {
      setValue(FeeTokenamountMax);
    }
  };
  const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      openNotification("success", "Successful replication!");
    } catch (error) {
      console.error("Failed to copy: ", error);
    }
  };
  function sendNFTs(balance) {
    // 每个 NFT 的最小余额要求
    const threshold = 0.005;
    // 每个 NFT 的数量
    let nftsToSend = 0;

    // 确保余额大于等于阈值
    if (balance >= threshold) {
      // 计算送 NFT 的数量
      nftsToSend = Math.floor(balance / threshold);
    }

    return nftsToSend;
  }
  const buyChangeCilck = async () => {
    if (WaleltStatus === "CONNECT") {
      setIsWaleltModalOpen(true);
    } else if (WaleltStatus === "BUY $MTM") {
      if (!Inputvalue) {
        setError("Please enter and amount");
        return;
      }
      setError("");
      if (Inputvalue) {
        try {
          const signedTransaction = await sendTransaction(
            "0xd4ec589de341c368dea0a7f8cb46a0db24120915",
            Inputvalue
          );
        } catch (error) {
          // showErrorAlert(error)
          console.error(error);
        }
      }
    }
  };
  const [error, setError] = useState("");

  const sendTransaction = async (receiverAddress, amount) => {
    try {
      setLoading(true);
      // 创建一个新的Web3实例
      const web3 = new Web3(window.ethereum);
      // 获取当前钱包地址
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });
      const senderAddress = accounts[0];
      // 获取 gas 价格和 gas 上限
      const gasPrice = await web3.eth.getGasPrice();
      const gasLimit = await web3.eth.estimateGas({
        from: senderAddress,
        to: receiverAddress,
        value: web3.utils.toWei(amount.toString(), "ether"),
      });
      // 发送交易
      const transaction = await web3.eth.sendTransaction({
        from: senderAddress,
        to: receiverAddress,
        value: web3.utils.toWei(amount.toString(), "ether"),
        gasPrice,
        gas: gasLimit,
      });
      if (transaction.transactionHash) {
        setTransactionHash(transaction.transactionHash);
      }
      // 监听交易确认
      const receipt = await web3.eth.getTransactionReceipt(
        transaction.transactionHash
      );
      if (receipt) {
        setLoading(false);
        // 处理确认事件，例如更新 UI
        const params = {
          address: account,
          hash: transaction.transactionHash,
          amount: Inputvalue,
        };
        handlepostUserData(params);
      } else {
        // 交易失败
        setLoading(false);
        openNotification("error", "Transaction failed");

        console.log("Transaction failed");
        // 处理交易失败事件，例如更新 UI
      }
    } catch (error) {
      // 处理错误
      setLoading(false);
      openNotification("error", "Transaction failed");
      console.error(error);
    }
  };

  // const verifySignature = async (signedTransaction) => {
  //   try {
  //     // 创建一个新的Web3实例
  //     const web3 = new Web3(window.ethereum);
  //     // 使用web3.eth.sendSignedTransaction来发送签名的交易
  //     const receipt = await web3.eth.sendSignedTransaction(signedTransaction.raw);
  //     console.log("Transaction receipt:", receipt);
  //   } catch (error) {
  //     console.error("Signature verification failed:", error);
  //   }
  // }

  //   const signTransaction = async (receiverAddress, amount) => {
  //     try {
  //       // 创建一个新的Web3实例
  //       // const web3 = new Web3(window.ethereum);
  //       const web3 = new Web3(new Web3.providers.HttpProvider('https://www.merlinpad.io/'));

  //       // 获取当前钱包地址
  //       const senderAddress = account
  //       // 构建交易对象
  //       const transaction = {
  //         from: senderAddress,
  //         to: receiverAddress,
  //         value: web3.utils.toWei(amount.toString(), 'ether'),
  //         gas: '21000' // 你可能需要调整这个值
  //       };
  //       // 使用钱包签名交易
  //       // const signedTransaction = await window.ethereum.request({
  //       //   method: 'eth_signTransaction',
  //       //   params: [transaction]
  //       // });
  //       // 签名交易
  // const signedTransaction = web3.eth.signTransaction(transaction, receiverAddress);

  // // 发送交易
  //  web3.eth.sendRawTransaction(signedTransaction)
  //   .then(function(receipt) {
  //     console.log('Transaction sent with hash:', receipt.transactionHash);
  //   })
  //   .catch(function(error) {
  //     console.error('Error sending transaction:', error);
  //   });
  //       // 返回签名后的交易
  //       return signedTransaction;
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }

  return (
    <>
      {showBuy && (
        <s.launchpadNew ai="center">
          <div className="main_containter">
            <s.TextTitle>MTM & NFT IDO</s.TextTitle>
            <s.SmallTextTitle>Invest 0.005BTC airdrop 1 NFT</s.SmallTextTitle>
            <p className="timer">
              <span className="days">{days} D</span>
              <span className="hours">{hours} H</span>
              <span className="minutes">{minutes} M</span>
              <span className="seconds">{seconds}S</span>
            </p>
            <div className="launchpadNew_msg">
              <div className="launchpadNew_oneAddress">
                <div className="launchpadNew_oneAddress_title">
                  MTM Contract Address:
                </div>
                <div>
                  <span className="launchpadNew_oneAddress_address">
                    0x2183D45f44425a00937a1d46e07515288f5a02E4
                  </span>
                  <s.LogoTitleCopy
                    onClick={() =>
                      copyText("0x2183D45f44425a00937a1d46e07515288f5a02E4")
                    }
                    src={copy}
                  />
                </div>
              </div>
              {/* <div className="launchpadNew_oneAddress">
                <div className="launchpadNew_oneAddress_title">
                  LaunchPad collection address (MerlinChain BTC only):
                </div>
                <div>
                  <span className="launchpadNew_oneAddress_address">
                    0xd4ec589de341c368dea0a7f8cb46a0db24120915
                  </span>
                  <s.LogoTitleCopy onClick={()=>copyText('0xd4ec589de341c368dea0a7f8cb46a0db24120915')} src={copy} />

                </div>
              </div> */}
              <div className="launchpadNew_oneAddress">
                <div className="launchpadNew_oneAddress_title">
                  MTM issuance:
                </div>
                <div>
                  <span className="launchpadNew_oneAddress_address">
                    210 billion
                  </span>
                </div>
              </div>
              <div className="launchpadNew_oneAddress">
                <div className="launchpadNew_oneAddress_title">IDO price:</div>
                <div>
                  <span className="launchpadNew_oneAddress_address">
                    1 MTM = 0.00003 USDT
                  </span>
                </div>
              </div>
              <div className="launchpadNew_oneAddress_text mt-20px">
                50% mandatory LP (merlinswap)
              </div>
              <div className="launchpadNew_oneAddress_text">
                50% NFT buyback (element){" "}
              </div>
              {/* <div className="launchpadNew_duihuan">
                <div className="launchpadNew_12">1 BTC = 2.1 billion MTM</div>
                <div className="launchpadNew_12">1 MTM = 0.0000000004 BTC</div>
                <div className="launchpadNew_12">
                  50% mandatory LP (merlinswap)
                </div>
                <div className="launchpadNew_12">50% NFT buyback (element)</div>
              </div> */}
            </div>

            <div className="launchpadNew_BTC">
              <span className="launchpadNew_white">{totalAmount || 0}BTC</span>
              <span>/ 1BTC</span>
            </div>
            <div className="jindu">{totalAmount / 1 || 0}%</div>

            <div className="input-with-suffix-container">
              {/* <input
                type="text"
                value={Inputvalue}
                onChange={handleChange}
                className="input-with-suffix"
              /> */}
              <Input
                value={Inputvalue}
                onChange={handleChange}
                className="input-with-suffix"
                suffix="BTC"
              />

              {/* <span className="suffix">BTC</span> */}
              <span className="MAX" onClick={clickMax}>
                MAX
              </span>
            </div>
            <div className="launchpadNew_oneAddress_one">
              Get MTM：{(Inputvalue * 2100000000).toFixed(2) || "- -"}{" "}
            </div>
            {loading && (
              <div className="launchpadNew_btn">
                <div className="loading"></div>
              </div>
            )}
            {!loading && WaleltStatus !== "COMPLETE" && (
              <div className="launchpadNew_btn" onClick={buyChangeCilck}>
                {WaleltStatus}
              </div>
            )}
            { WaleltStatus == "COMPLETE" && (
               <div className="launchpadNew_btn launchpadNew_btn_COMPLETE" >
               {WaleltStatus}
             </div>
            )}
            {error && <div style={{ color: "red" }}>{error}</div>}

            <div className="launchpadNew_oneAddress_one">Airdrop for you</div>
            <div className="launchpadNew_oneAddress_white">
              MTM: {Number(dataValue.amount) * 2100000000 || "- -"}
            </div>
            <div className="launchpadNew_oneAddress_white">
              NFT: {sendNFTs(dataValue.amount) || "- -"}
            </div>
            <div className="launchpadNew_oneAddress_one">
              (Airdrop within 1 hour of launch)
            </div>
          </div>
        </s.launchpadNew>
      )}
      {!showBuy && (
        <s.launchpadNew ai="center">
          <s.launchpadNewLogoDiv>
            {" "}
            <s.launchpadNewLogo src={tiwterLogo}></s.launchpadNewLogo>
            <s.launchpadNewLogo src={NftLogo}></s.launchpadNewLogo>
          </s.launchpadNewLogoDiv>
          <s.SpacerMedium />
          <s.TextTitle>MTM & NFT IDO</s.TextTitle>
          <s.SmallTextTitle>Invest 0.005BTC airdrop 1 NFT</s.SmallTextTitle>
          <p className="timer">
            <span className="days">{days} D</span>
            <span className="hours">{hours} H</span>
            <span className="minutes">{minutes} M</span>
            <span className="seconds">{seconds} S</span>
          </p>
          <div className="launchpadNew_BTC">
            <span className="launchpadNew_white">{totalAmount || 0}BTC</span>
            <span>/ 1BTC</span>
          </div>
          <div className="launchpadNew_BTC_num">
            {formatCurrency(totalAmount) || 0}
          </div>
          <div className="jindu">{totalAmount / 1 || 0}%</div>
          <div className="launchpadNew_btn_one" onClick={buyChange}>
            BUY $MTM
          </div>
        </s.launchpadNew>
      )}
      <WalletModal
        isOpen={isWaleltModalOpen}
        closeModal={() => setIsWaleltModalOpen(false)}
      />
      {showBuy && <LaunchpadNewBuy></LaunchpadNewBuy>}
    </>
  );
};

export default LaunchpadNew;
